import React from 'react';
import Layout from '../../components/Layout/layout';
import EmployerBrandingArticle from '../../Views/Website/Articles/Employer-Branding/employer-branding';

const EmployerBrandingArticlePage: React.FC = (props: any) => {
  return (
    <Layout>
      <EmployerBrandingArticle location={props.location.href} />
    </Layout>
  );
};

export default EmployerBrandingArticlePage;
