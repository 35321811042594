import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../components/Login/Login';
import * as styles from '../articles.module.scss';
import ArticleImg from '../../../../assets/IMG/freebies/employer-branding/1.png';
import articlePDF from "../../../../assets/pdfs/employer branding.pdf";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_BaseURL } from '../../../../Services/ApiRoutes';
import { Link } from 'gatsby';

interface Props {
  location: string;
}

const EmployerBrandingArticle: React.FC<Props> = ({ location }) => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const sixthRef = useRef(null);
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Articles/Employer-Branding` : "";
  const scroll = (ref: any) => ref.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Articles :: A BUSINESS OWNER'S ESSENTIAL GUIDE TO EMPLOYER BRANDING</title><meta charSet='utf-8' />
        <meta property="og:description" content="We’ve all heard about branding, whether it’s something you focus
            a lot of your time and energy on or not. It’s the combination of
            the image you put out to the world, along with your values and
            beliefs, your products or service, and the way you interact with
            people." />
        <meta property="og:image" content={ArticleImg} />
        <meta property="og:title" content="PrimeHR :: A BUSINESS OWNER'S ESSENTIAL GUIDE TO EMPLOYER BRANDING" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content="https://primehr.co.uk/Articles/Employer-Branding" />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Articles", Text: "Articles" },
        { To: "", Text: "Employer Branding" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>A BUSINESS OWNER'S ESSENTIAL GUIDE TO EMPLOYER BRANDING</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            {/* <span>Written by <Link to="https://www.linkedin.com/in/nikki-hufton-bsc-hons-mcipd-fcmi-mnlp-a7528854/">Nikki Hufton</Link></span> */}
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={ArticleImg} />
          </div>

          <h2>AVOIDING ‘THE GREAT RESIGNATION’</h2>

          <ul>
            <li><a onClick={() => scroll(firstRef)}>Avoiding ‘The Great Resignation</a></li>
            <li><a onClick={() => scroll(secondRef)}>Your Current Employer Brand</a></li>
            <li><a onClick={() => scroll(thirdRef)}>Consciously Creating Your Brand</a></li>
            <li><a onClick={() => scroll(fourthRef)}>People Are Key</a></li>
            <li><a onClick={() => scroll(fifthRef)}>Measure Your Plan’s Success</a></li>
            <li><a onClick={() => scroll(sixthRef)}>Need Some Expert HR Advice?</a></li>
          </ul>

          <p>
            We’ve all heard about branding, whether it’s something you focus
            a lot of your time and energy on or not. It’s the combination of
            the image you put out to the world, along with your values and
            beliefs, your products or service, and the way you interact with
            people.
          </p>
          <p>
            These things all combine to create a feeling in people - good or bad - that
            gives them an impression of what your business is really like
          </p>
          <p>
            But when it comes to employer branding, you may be a little less sure. Many
            businesses simply don’t give their employer branding enough thought.
          </p>
          <p>
            And it’s really important. Especially in today’s job market.
          </p>
          <p>
            You see, thanks to things like social media and job sites, as well as the rise in
            remote working post-pandemic, it’s even easier for candidates to find their
            ideal job roles. Thanks to technology, it isn’t just a local pool of candidates
            your business is fighting to attract, it can be national, or even global.
          </p>
          <p>
            Right now it’s a candidate-driven market. And thanks to this, many
            businesses are seeing a mass exodus of employees, dubbed ‘The Great
            Resignation’. There is far less risk to employees who are dissatisfied in their
            current roles right now. They can hand in their notice and feel relatively
            secure that their next job is just around the corner.
          </p>
          <p>
            For employers, it’s a scarier time. Candidates can afford to be more choosy
            about the roles they take on, so employers really need to demonstrate just
            how great they are to work for. And that’s not always easy
          </p>
          <p>
            Developing and maintaining an excellent employer brand can really give
            your business a competitive edge over its rivals. That’s because today,
            candidates are looking for more than a decent salary (although that’s still
            important). They want to work for a business that does something
            meaningful and that really makes a difference.
          </p>
          <p>
            It’s for this reason that you need an effective employer branding strategy.
          </p>
          <p>
            This should encompass everything, from recruitment and onboarding new
            employees, to your company culture, and your values and beliefs. If done
            correctly, it will help you to attract, engage, and retain the very best people
            for your business. And we all know, the people in your business are your
            real key to success.
          </p>
          <p>
            So where do you start?
          </p>

          <h3 ref={firstRef}>YOUR CURRENT EMPLOYER BRAND</h3>
          <p>
            What does your current employer brand look like? Even if it’s not
            something you’ve ever spent time developing, you already have
            an employer brand, like it or not! In order to shape it into what
            you'd like it to be, you need a clear understanding of how your
            employees currently view you as an employer.
          </p>
          <p>
            Your employer brand will be reflected in the way your employees
            feel about your business, and what people outside of the
            business are saying about you. And while they might not be so
            vocal in letting you know their true feelings, there are a number
            of ways you can find out.
          </p>
          <p>
            You could start by surveying your employees. Use a free service,
            such as Survey Monkey or CrowdSignal. This will allow you to
            email surveys to your employees and collate the results very
            easily. Surveys like these can be used with both current staff and
            leavers as part of the exit process.
          </p>
          <p>
            You can choose whether you’d like to do this anonymously or
            not, though if you want real honesty it may be a good option to
            do so.
          </p>
          <p>
            Websites like Glassdoor give employees an opportunity to rate
            their employers, too, so you can look into this.
          </p>
          <p>
            Combining all of this information should give you a clear
            indication of how well your employer brand reflects what you
            want it to be.
          </p>

          <h3 ref={secondRef}>CONSCIOUSLY CREATING YOUR BRAND</h3>
          <p>
            Now it’s time to decide what you want your employer brand to be. At
            this stage, it’s very important to point out that you must ensure your
            employer brand aligns with your core values and brand identity.
          </p>
          <p>
            If it doesn’t, you run the risk of confusing potential employees, customers and
            even suppliers. This makes it all the more difficult to connect and engage with
            people, and your credibility goes out the window, too.
          </p>
          <p>
            Do you want people to see you as a fun business to work for? Do you want to
            be seen as approachable? Ethical? Inclusive? Secure? Flexible? There are so
            many things to cover, so it’s important you begin this process with a very
            clear picture of what you want to become.
          </p>
          <p>
            Remember, your employer brand will work to differentiate your business
            from your competitors, just as your overall brand will. Take the time to make
            it very personal and mould it to exactly what you’d like it to become.
          </p>
          <p>
            Look at the research you’ve carried out. Does your feedback suggest areas
            that you’d like to make significant improvement on? Which areas are you
            keen to strengthen, and which areas are already strong? How can you
            continue to support your strengths or develop them further?
          </p>
          <p>
            Your employer brand may naturally evolve over time. As your business grows
            you may develop new values or even support causes that you weren’t able to
            before. Make sure you revisit your employer brand strategy often, making
            updates where necessary.
          </p>
          <p>
            When your plan is finished, show it off! Share it with your directors and
            employees and listen to their feedback. It’s really important to get buy-in
            across the board for your branding plan to be successful.
          </p>

          <h3 ref={thirdRef}>PEOPLE ARE KEY</h3>

          <p>
            You may have heard this before: people buy from people. When it
            comes to recruiting in today’s job market, you’ll need to be
            prepared to sell your business to your candidates just as much as
            your candidate needs to sell themselves to you.
          </p>
          <p>
            Your brand is no longer limited to the office and its customers. Your online
            presence opens you up to customers, potential customers, employees,
            potential employees, suppliers and potential suppliers, as well as the rest of
            the general public. You should also make sure that everything you do online
            also reflects your employer brand.
          </p>
          <p>
            If people leave reviews, take the time to reply to them - good or bad. Thank
            people for their feedback; address issues that are raised. If people start a
            conversation on your social media platforms, don’t be afraid to join in. The
            more involved you are, the more likely it is that your audience feels like they
            know you. This is a really good way to build successful relationships with not
            only your potential customers, but your potential employees too.
          </p>
          <p>
            Get HR working with your marketing team to create content that also
            supports your employer brand. Make sure your website and social media
            accounts tell your story. Show off the people who are already working for you.
            Explain your beliefs and how they came about, demonstrate what you can
            offer, as both a business and an employer. Really highlight why your business
            is unique.
          </p>
          <p>
            The more you can communicate about your business and its core values and
            beliefs, the more likely it is that the people who share them will actively seek
            you out, rather than you chasing after them. And these are the people that
            you want working with you.
          </p>
          <p>
            The better your hires, the stronger your business and its employer brand
            becomes. When people are happier and more engaged, not only does
            productivity increase (and your turnover, with it), but the more committed
            your team is, and the longer they’re likely to stay with you.
          </p>

          <h3 ref={fourthRef}>MEASURE YOUR PLAN’S SUCCESS</h3>

          <p>
            What would a business be if we didn’t track and measure? The
            same thing goes for tracking and measuring how well our
            branding is working out for us.
          </p>
          <p>
            Create a set of KPIs (Key Performance Indicators) to measure how successful
            your new plan is. Look at things like employee retention rates, the number of
            applicants you’re attracting per position, how long it takes to fill a position, the
            diversity within the business, absence, the level of employee engagement and
            productivity.
          </p>
          <p>
            These indicators will work together to show you whether there’s still room for
            improvement, and which areas are going really well. Even if all the indications
            are great, don’t rest on your laurels. Make sure you are always nurturing and
            developing your employer brand to ensure things don’t go south.
          </p>
          <p>
            Next you can look to see if your employer brand directly improves your EVP
            (that’s your Employee Value Proposition). This is the value your employees get
            from working for you.
          </p>
          <p>
            It’s no good developing a smashing employer brand if it’s all for show. You
            need to really stand behind the values and beliefs that you’re putting out to
            the world, and create a workplace that lives up to its reputation.
          </p>
          <p>
            Remember, your employees are drawn to a role by <b>more than just
              money</b>. They also want great benefits, perks and an excellent culture.
          </p>
          <p>
            When you get it right, you should get a great return on investment.
          </p>
          <p>
            Recruitment will be made easier, your employee retention should be high,
            your engagement should be excellent, with higher productivity and
            increased turnover, absenteeism should be down, and you should also gain
            more referrals (interestingly, 82% of employers rate employee referrals
            above other sources for generating the best return on investment).
          </p>
          <p>
            Remember, the potential of your company lies in the hands of your people.
            Attract and retain the right people, and your potential is limitless. But if you
            get your employer branding wrong, not only will you struggle to attract
            people to the available roles, but you risk making bad hires and your
            retention rate will be shocking as well.
          </p>
          <p>
            <b>Right now</b>, it’s tricky to find and attract the right candidates for your
            business. But if you create and maintain the right employer brand, you’ll
            make your business stand out from your competitors by making it far more
            attractive to the right people.
          </p>

          <h3 ref={fifthRef}>NEED SOME EXPERT HR ADVICE?</h3>

          <p>
            If you want to focus on your employer brand and your EVP
            and not sure where to start, we’d love to help you.
          </p>
          <p>
            Simply give us a call or drop us an email to arrange a
            conversation on <a href='tel:01158 372 772'>01158 372 772</a> or email <a href='mailto: claire@primehr.co.uk'>claire@primehr.co.uk</a>
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.Download}>
            <a download href={articlePDF}>
              <i>
                <FontAwesomeIcon icon={faCloudDownloadAlt} />
              </i>
              Download Article
            </a>
          </div>

          <div className={styles.SectionSideInfo}>
            <h4>Found This Article Useful?</h4>
            <p>If you found this article or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

            <div className={styles.Download}>
              <Link to="/Register">
                Create an Account
              </Link>
            </div>
          </div>

          <h4><span>Or</span></h4>

          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default EmployerBrandingArticle;